<template>
  <div class="card">
    <div class="container">
      <Practice v-if="isPractice && !showTip && !showAns"></Practice>
      <isRight :answer="correct_type" v-if="isPractice && showAns"></isRight>
      <p class="tip" v-if="showTip && !showAns && isPractice">
        请按
        <span class="type" v-if="type == 1">颜色</span>
        <span class="type" v-if="type == 2">形状</span>
        <span class="type" v-if="type == 3">数量</span> 归类
      </p>
      <div class="main">
        <ul class="topItems">
          <li
            v-for="(item, index) in dropDataList"
            :key="index"
            @drop="drop($event, index)"
            @dragenter="dragenter($event, index)"
            @dragleave="dragleave"
            @dragover.prevent
            @mousedown.prevent
            draggable="false"
          >
            <img @mousedown.prevent draggable="false" :src="item.src" alt="" />
            <div class="hoverBox" v-show="hoverIndex == index"></div>
          </li>
        </ul>
        <ul class="bottom" v-if="!showTip && !showAns">
          <li v-for="(item, index) in cardList" :key="index">
            <div
              class="dropBox currentBox"
              v-if="current == index"
              :style="{
                right: (cardList.length - index) * 20 + 'px',
                zIndex: cardList.length - index,
                cursor: 'pointer',
              }"
            >
              <img
                draggable="true"
                @dragstart="dragstart($event, currentCard)"
                @dragend="dragend"
                :src="item.src"
                alt=""
              />
            </div>
            <div
              class="dropBox"
              draggable="false"
              @dragstart.prevent
              v-if="current < index"
              :style="{
                right: (cardList.length - index) * 20 + 'px',
                zIndex: cardList.length - index,
              }"
            >
              <img
                draggable="false"
                @dragstart.prevent
                :src="item.src"
                alt=""
              />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import Practice from '@/components/practice.vue';
import isRight from '@/components/isRight.vue';
import { saveSubjectiveAns } from '@/api/index';
//测试数据
let practiceData = [
  {
    id: 1,
    name: 'cross2red',
    color: 'red',
    shape: 'cross',
    num: '2',
    type: 1,
    src: require('@/assets/image/card/test/cross2red.png'),
  },
  {
    id: 2,
    name: 'triangle1yellow',
    color: 'yellow',
    shape: 'triangle',
    num: '1',
    type: 2,
    src: require('@/assets/image/card/test/triangle1yellow.png'),
  },
  {
    id: 3,
    name: 'triangle4green',
    color: 'green',
    shape: 'triangle',
    num: '4',
    type: 3,
    src: require('@/assets/image/card/test/triangle4green.png'),
  },
];
//正式数据
let formalData = [
  {
    id: 1,
    name: 'circle1blue',
    color: 'blue',
    shape: 'circle',
    num: '1',
    src: require('@/assets/image/card/reaction/circle1blue.png'),
  },
  {
    id: 2,
    name: 'circle1green',
    color: 'green',
    shape: 'circle',
    num: '1',
    src: require('@/assets/image/card/reaction/circle1green.png'),
  },
  {
    id: 4,
    name: 'circle1yellow',
    color: 'yellow',
    shape: 'circle',
    num: '1',
    src: require('@/assets/image/card/reaction/circle1yellow.png'),
  },
  {
    id: 5,
    name: 'circle2blue',
    color: 'blue',
    shape: 'circle',
    num: '2',
    src: require('@/assets/image/card/reaction/circle2blue.png'),
  },
  {
    id: 6,
    name: 'circle2green',
    color: 'green',
    shape: 'circle',
    num: '2',
    src: require('@/assets/image/card/reaction/circle2green.png'),
  },
  {
    id: 7,
    name: 'circle2red',
    color: 'red',
    shape: 'circle',
    num: '2',
    src: require('@/assets/image/card/reaction/circle2red.png'),
  },
  {
    id: 8,
    name: 'circle2yellow',
    color: 'yellow',
    shape: 'circle',
    num: '2',
    src: require('@/assets/image/card/reaction/circle2yellow.png'),
  },
  {
    id: 9,
    name: 'circle3blue',
    color: 'blue',
    shape: 'circle',
    num: '3',
    src: require('@/assets/image/card/reaction/circle3blue.png'),
  },
  {
    id: 10,
    name: 'circle3green',
    color: 'green',
    shape: 'circle',
    num: '3',
    src: require('@/assets/image/card/reaction/circle3green.png'),
  },
  {
    id: 11,
    name: 'circle3red',
    color: 'red',
    shape: 'circle',
    num: '3',
    src: require('@/assets/image/card/reaction/circle3red.png'),
  },
  {
    id: 12,
    name: 'circle3yellow',
    color: 'yellow',
    shape: 'circle',
    num: '3',
    src: require('@/assets/image/card/reaction/circle3yellow.png'),
  },
  {
    id: 13,
    name: 'circle4blue',
    color: 'blue',
    shape: 'circle',
    num: '4',
    src: require('@/assets/image/card/reaction/circle4blue.png'),
  },
  {
    id: 14,
    name: 'circle4green',
    color: 'green',
    shape: 'circle',
    num: '4',
    src: require('@/assets/image/card/reaction/circle4green.png'),
  },
  {
    id: 15,
    name: 'circle4red',
    color: 'red',
    shape: 'circle',
    num: '4',
    src: require('@/assets/image/card/reaction/circle4red.png'),
  },
  {
    id: 16,
    name: 'circle4yellow',
    color: 'yellow',
    shape: 'circle',
    num: '4',
    src: require('@/assets/image/card/reaction/circle4yellow.png'),
  },
  {
    id: 17,
    name: 'cross1blue',
    color: 'blue',
    shape: 'cross',
    num: '1',
    src: require('@/assets/image/card/reaction/cross1blue.png'),
  },
  {
    id: 18,
    name: 'cross1green',
    color: 'green',
    shape: 'cross',
    num: '1',
    src: require('@/assets/image/card/reaction/cross1green.png'),
  },
  {
    id: 19,
    name: 'cross1red',
    color: 'red',
    shape: 'cross',
    num: '1',
    src: require('@/assets/image/card/reaction/cross1red.png'),
  },
  {
    id: 20,
    name: 'circle1blue',
    color: 'yellow',
    shape: 'cross',
    num: '1',
    src: require('@/assets/image/card/reaction/cross1yellow.png'),
  },
  {
    id: 21,
    name: 'cross2blue',
    color: 'blue',
    shape: 'cross',
    num: '2',
    src: require('@/assets/image/card/reaction/cross2blue.png'),
  },
  {
    id: 22,
    name: 'cross2green',
    color: 'green',
    shape: 'cross',
    num: '2',
    src: require('@/assets/image/card/reaction/cross2green.png'),
  },
  {
    id: 23,
    name: 'cross2red',
    color: 'red',
    shape: 'cross',
    num: '2',
    src: require('@/assets/image/card/reaction/cross2red.png'),
  },
  {
    id: 24,
    name: 'cross2yellow',
    color: 'yellow',
    shape: 'cross',
    num: '2',
    src: require('@/assets/image/card/reaction/cross2yellow.png'),
  },
  {
    id: 25,
    name: 'cross3blue',
    color: 'blue',
    shape: 'cross',
    num: '3',
    src: require('@/assets/image/card/reaction/cross3blue.png'),
  },
  {
    id: 26,
    name: 'cross3green',
    color: 'green',
    shape: 'cross',
    num: '3',
    src: require('@/assets/image/card/reaction/cross3green.png'),
  },
  {
    id: 27,
    name: 'cross3red',
    color: 'red',
    shape: 'cross',
    num: '3',
    src: require('@/assets/image/card/reaction/cross3red.png'),
  },
  {
    id: 28,
    name: 'cross3yellow',
    color: 'yellow',
    shape: 'cross',
    num: '3',
    src: require('@/assets/image/card/reaction/cross3yellow.png'),
  },
  {
    id: 29,
    name: 'cross4blue',
    color: 'blue',
    shape: 'cross',
    num: '4',
    src: require('@/assets/image/card/reaction/cross4blue.png'),
  },
  {
    id: 30,
    name: 'cross4green',
    color: 'green',
    shape: 'cross',
    num: '4',
    src: require('@/assets/image/card/reaction/cross4green.png'),
  },
  {
    id: 31,
    name: 'cross4red',
    color: 'red',
    shape: 'cross',
    num: '4',
    src: require('@/assets/image/card/reaction/cross4red.png'),
  },
  {
    id: 33,
    name: 'star1blue',
    color: 'blue',
    shape: 'star',
    num: '1',
    src: require('@/assets/image/card/reaction/star1blue.png'),
  },
  {
    id: 34,
    name: 'star1green',
    color: 'green',
    shape: 'star',
    num: '1',
    src: require('@/assets/image/card/reaction/star1green.png'),
  },
  {
    id: 35,
    name: 'star1red',
    color: 'red',
    shape: 'star',
    num: '1',
    src: require('@/assets/image/card/reaction/star1red.png'),
  },
  {
    id: 36,
    name: 'star1yellow',
    color: 'yellow',
    shape: 'star',
    num: '1',
    src: require('@/assets/image/card/reaction/star1yellow.png'),
  },
  {
    id: 37,
    name: 'star2blue',
    color: 'blue',
    shape: 'star',
    num: '2',
    src: require('@/assets/image/card/reaction/star2blue.png'),
  },
  {
    id: 38,
    name: 'star2green',
    color: 'green',
    shape: 'star',
    num: '2',
    src: require('@/assets/image/card/reaction/star2green.png'),
  },
  {
    id: 39,
    name: 'star2red',
    color: 'red',
    shape: 'star',
    num: '2',
    src: require('@/assets/image/card/reaction/star2red.png'),
  },
  {
    id: 40,
    name: 'star2yellow',
    color: 'yellow',
    shape: 'star',
    num: '2',
    src: require('@/assets/image/card/reaction/star2yellow.png'),
  },
  {
    id: 41,
    name: 'star3blue',
    color: 'blue',
    shape: 'star',
    num: '3',
    src: require('@/assets/image/card/reaction/star3blue.png'),
  },
  {
    id: 43,
    name: 'star3red',
    color: 'red',
    shape: 'star',
    num: '3',
    src: require('@/assets/image/card/reaction/star3red.png'),
  },
  {
    id: 44,
    name: 'star3yellow',
    color: 'yellow',
    shape: 'star',
    num: '3',
    src: require('@/assets/image/card/reaction/star3yellow.png'),
  },
  {
    id: 45,
    name: 'star4blue',
    color: 'blue',
    shape: 'star',
    num: '4',
    src: require('@/assets/image/card/reaction/star4blue.png'),
  },
  {
    id: 46,
    name: 'star4green',
    color: 'green',
    shape: 'star',
    num: '4',
    src: require('@/assets/image/card/reaction/star4green.png'),
  },
  {
    id: 47,
    name: 'star4red',
    color: 'red',
    shape: 'star',
    num: '4',
    src: require('@/assets/image/card/reaction/star4red.png'),
  },
  {
    id: 48,
    name: 'star4yellow',
    color: 'yellow',
    shape: 'star',
    num: '4',
    src: require('@/assets/image/card/reaction/star4yellow.png'),
  },
  {
    id: 49,
    name: 'triangle1blue',
    color: 'blue',
    shape: 'triangle',
    num: '1',
    src: require('@/assets/image/card/reaction/triangle1blue.png'),
  },
  {
    id: 50,
    name: 'triangle1green',
    color: 'green',
    shape: 'triangle',
    num: '1',
    src: require('@/assets/image/card/reaction/triangle1green.png'),
  },
  {
    id: 51,
    name: 'triangle1red',
    color: 'red',
    shape: 'triangle',
    num: '1',
    src: require('@/assets/image/card/reaction/triangle1red.png'),
  },
  {
    id: 52,
    name: 'triangle1yellow',
    color: 'yellow',
    shape: 'triangle',
    num: '1',
    src: require('@/assets/image/card/reaction/triangle1yellow.png'),
  },
  {
    id: 54,
    name: 'triangle2green',
    color: 'green',
    shape: 'triangle',
    num: '2',
    src: require('@/assets/image/card/reaction/triangle2green.png'),
  },
  {
    id: 55,
    name: 'triangle2red',
    color: 'red',
    shape: 'triangle',
    num: '2',
    src: require('@/assets/image/card/reaction/triangle2red.png'),
  },
  {
    id: 56,
    name: 'triangle2yellow',
    color: 'yellow',
    shape: 'triangle',
    num: '2',
    src: require('@/assets/image/card/reaction/triangle2yellow.png'),
  },
  {
    id: 57,
    name: 'triangle3blue',
    color: 'blue',
    shape: 'triangle',
    num: '3',
    src: require('@/assets/image/card/reaction/triangle3blue.png'),
  },
  {
    id: 58,
    name: 'triangle3green',
    color: 'green',
    shape: 'triangle',
    num: '3',
    src: require('@/assets/image/card/reaction/triangle3green.png'),
  },
  {
    id: 59,
    name: 'triangle3red',
    color: 'red',
    shape: 'triangle',
    num: '3',
    src: require('@/assets/image/card/reaction/triangle3red.png'),
  },
  {
    id: 60,
    name: 'triangle3yellow',
    color: 'yellow',
    shape: 'triangle',
    num: '3',
    src: require('@/assets/image/card/reaction/triangle3yellow.png'),
  },
  {
    id: 61,
    name: 'triangle4blue',
    color: 'blue',
    shape: 'triangle',
    num: '4',
    src: require('@/assets/image/card/reaction/triangle4blue.png'),
  },
  {
    id: 62,
    name: 'triangle4green',
    color: 'green',
    shape: 'triangle',
    num: '4',
    src: require('@/assets/image/card/reaction/triangle4green.png'),
  },
  {
    id: 63,
    name: 'triangle4red',
    color: 'red',
    shape: 'triangle',
    num: '4',
    src: require('@/assets/image/card/reaction/triangle4red.png'),
  },
  {
    id: 64,
    name: 'triangle4yellow',
    color: 'yellow',
    shape: 'triangle',
    num: '4',
    src: require('@/assets/image/card/reaction/triangle4yellow.png'),
  },
];

export default {
  data() {
    return {
      type: 1, //当前类型 1颜色 2形状 3数量
      typeList: [1, 2, 3],
      showTip: true,
      showAns: false, //显示结果
      isPractice: true,
      dropData: '',
      dropIndex: null,
      dropDataList: [
        {
          id: 1,
          name: 'circle1red',
          color: 'red',
          shape: 'circle',
          num: '1',
          src: require('@/assets/image/card/reaction/top/circle1red.png'),
        },
        {
          id: 2,
          name: 'triangle2blue',
          color: 'blue',
          shape: 'triangle',
          num: '2',
          src: require('@/assets/image/card/reaction/top/triangle2blue.png'),
        },
        {
          id: 3,
          name: 'star3green',
          color: 'green',
          shape: 'star',
          num: '3',
          src: require('@/assets/image/card/reaction/top/star3green.png'),
        },
        {
          id: 4,
          name: 'cross4yellow',
          color: 'yellow',
          shape: 'cross',
          num: '4',
          src: require('@/assets/image/card/reaction/top/cross4yellow.png'),
        },
      ],
      current: 0,
      currentCard: '',
      cardList: [], //当前卡片显示数据
      testData: [], //测试数据
      original: [], //初始数据
      originalData: [], //用户操作数据
      startTime: 0, //起始时间
      endTime: 0, //结束时间
      startTimeTotal: 0, //起始总时间
      endTimeTotal: 0, //结束总时间
      totalTime: 0, //用时
      hoverIndex: null,
      timer1: null,
      timer2: null,
      correct_type: '',
    };
  },
  components: {
    Practice,
    isRight,
  },
  watch: {
    type() {
      this.typeChange();
    },
    current() {
      this.startTime = this.$util.getTimestamp();
      if (this.current == this.cardList.length) {
        if (this.type == this.typeList[2]) {
          this.finish();
        } else {
          let sub = 0;
          this.typeList.forEach((item, index) => {
            if (this.type == item) {
              sub = index;
            }
          });
          this.type = this.typeList[sub + 1];
        }
      }
    },
  },
  methods: {
    dragenter(event, index) {
      this.hoverIndex = index;
    },
    dragleave() {},
    dragstart(event, data) {
      event.dataTransfer.setData('item', data);
    },
    dragend(event) {
      this.hoverIndex = null;
      event.dataTransfer.clearData();
    },
    drop(event, index) {
      event.preventDefault();
      event.stopPropagation();
      this.dropIndex = index;
      let data = event.dataTransfer.getData('item');
      this.dropData = data;
      this.dropEnd(index);
    },
    //拖拽后数据处理
    dropEnd(index) {
      let keyName = null;
      if (this.type === 1) {
        keyName = 'color';
      }
      if (this.type === 2) {
        keyName = 'shape';
      }
      if (this.type === 3) {
        keyName = 'num';
      }
      this.dropDataList.forEach((item) => {
        if (this.cardList[this.current][keyName] == item[keyName]) {
          this.cardList[this.current].correct_answer = item.name; //正确答案
        }
      });
      this.cardList[this.current].answer = this.dropDataList[index].name; //用户答案
      if (
        this.cardList[this.current].correct_answer ==
        this.cardList[this.current].answer
      ) {
        //是否正确
        this.cardList[this.current].correct_type = 1;
        if (this.isPractice) {
          this.correct_type = 1;
        }
      } else {
        this.cardList[this.current].correct_type = 0;
        if (this.isPractice) {
          this.correct_type = 0;
        }
      }
      this.endTime = this.$util.getTimestamp();
      this.cardList[this.current].time = this.endTime - this.startTime;
      this.originalData.push({
        name: this.cardList[this.current].name,
        correct_answer: this.cardList[this.current].correct_answer,
        answer: this.cardList[this.current].answer,
        type: this.cardList[this.current].type,
        time: this.cardList[this.current].time,
        correct_type: this.cardList[this.current].correct_type,
      });
      if (this.isPractice) {
        this.showAns = true;
        setTimeout(() => {
          this.showAns = false;
          this.current++;
        }, 1000);
      } else {
        if (this.cardList[this.current].correct_type === 1) {
          this.$message({
            message: '正确，继续加油！',
            type: 'success',
            duration: 1000,
          });
        } else {
          this.$message({
            message: '错误，继续加油！',
            type: 'error',
            duration: 1000,
          });
        }
        this.current++;
      }
    },
    //生成原始数据
    createData() {
      let randomCard = this.$util.shuffle(formalData);
      let type = null;
      let original = [];
      randomCard.forEach((item, index) => {
        if (index < 20) {
          type = this.typeList[0];
        } else if (index >= 20 && index < 40) {
          type = this.typeList[1];
        } else if (index >= 40 && index < 60) {
          type = this.typeList[2];
        }
        original.push({
          type: type,
          name: item.name,
          color: item.color,
          shape: item.shape,
          num: item.num,
          src: item.src,
        });
      });
      let colorList = original.slice(0, 20);
      let shapeList = original.slice(20, 40);
      let numList = original.slice(40, 60);
      this.original = this.discontinuous(colorList, 'color')
        .concat(this.discontinuous(shapeList, 'shape'))
        .concat(this.discontinuous(numList, 'num'));
    },
    //保证没有3个连续
    discontinuous(list, type) {
      list.forEach((item, index) => {
        if (
          list[index + 2] &&
          list[index + 1][type] === list[index + 2][type] &&
          list[index + 1][type] === list[index][type]
        ) {
          let newArr = list.splice(index + 1, 1);
          // if (index - 1 >= 0) {
          //   list.splice(index - 1, 0, newArr[0]);
          // } else {
          if (newArr[0][type] === list[0].type) {
            list.push(newArr[0]);
          } else {
            list.unshift(newArr[0]);
          }
          // }
        }
      });
      return list;
    },
    typeChange() {
      this.showTip = true;
      this.current = 0;
      setTimeout(
        () => {
          this.showTip = false;
          if (this.isPractice) {
            this.cardList = this.testData.filter(
              (item) => item.type === this.type
            );
          } else {
            this.cardList = this.original.filter(
              (item) => item.type === this.type
            );
          }
          this.startTime = this.$util.getTimestamp();
          this.$forceUpdate();
        },
        this.isPractice ? 3000 : 500
      );
    },
    //完成
    finish() {
      this.endTimeTotal = this.$util.getTimestamp();
      this.totalTime = this.endTimeTotal - this.startTimeTotal;
      if (this.isPractice) {
        //练习题
        // if (this.totalTime >= 36000) {
          this.$router.replace({
            path: '/practiceEnd',
            query: {
              source: 'card',
            },
          });
        // } else {
        //   this.$router.replace({
        //     path: '/relax',
        //     query: {
        //       time: 36000 - this.totalTime,
        //       path: '/practiceEnd?source=card',
        //     },
        //   });
        // }
      } else {
        let typeRight = [
          { name: '颜色', type: 1, right: 0 },
          { name: '形状', type: 2, right: 0 },
          { name: '数量', type: 3, right: 0 },
        ];
        this.originalData.forEach((item) => {
          if (item.correct_type === 1) {
            if (item.type === 1) {
              typeRight[0].right++;
            }
            if (item.type === 2) {
              typeRight[1].right++;
            }
            if (item.type === 3) {
              typeRight[2].right++;
            }
          }
        });
        let query = {
          fmUserId: this.$util.getStorage('fmUserId'),
          fmProductQuestionId: '1',
          quesNum: '1-1',
          data1Name: 'typeRight',
          data1: JSON.stringify(typeRight),
          totalTime: this.totalTime > 240000 ? 240000 : this.totalTime,
          dataJason: JSON.stringify(this.originalData),
          remark:
            'data1:分类规则正确的数量；name:拖拽图片标识；answer：用户答案；correct_answer：正确答案，type:当前答题类型 1颜色 2形状 3数量,time:时长ms，correct_type：是否正确，1正确 0错误；',
        };
        // console.log(query);
        saveSubjectiveAns(query)
          .then((res) => {
            if (res.code === 0) {
              //大题编号
              sessionStorage.setItem(
                'fmProductQuestionId',
                res.data.fmProductQuestionId
              );
              //小题编号
              sessionStorage.setItem('quesNum', res.data.quesNum);
              //是否完成
              sessionStorage.setItem('isFinished', res.data.isFinished);
              // if (this.totalTime > 240000 - 1000) {
                this.$router.replace({
                  path: '/getCard',
                  query: {
                    id: 1,
                    current: '1-1',
                  },
                });
              // } else {
              //   this.$router.replace({
              //     path: '/relax',
              //     query: {
              //       time: 240000 - this.totalTime,
              //       path: '/getCard?id=1&current=1-1',
              //     },
              //   });
              // }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  created() {
    this.typeList = this.$util.shuffle(this.typeList);
    this.type = this.typeList[0];
    document.body.ondrop = function (event) {
      event.preventDefault();
      event.stopPropagation();
    };
    if (this.$route.query.practice) {
      //正式
      this.isPractice = false;
      this.createData();
    } else {
      //练习
      this.testData = practiceData;
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer1);
    clearTimeout(this.timer2);
  },
  mounted() {
    //超时自动提交
    if (this.isPractice) {
      this.timer1 = setTimeout(() => {
        this.finish();
      }, 36000);
    } else {
      this.timer2 = setTimeout(() => {
        this.finish();
      }, 240000);
    }
    this.startTimeTotal = this.$util.getTimestamp();
    this.typeChange();
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1900px) {
  .card {
    display: table-cell;
    vertical-align: middle;
    .container {
      .tip {
        color: #5f9160;
        text-align: center;
        font-size: 60px;
        line-height: 630px;
        font-family: 'SYHeiBold';
        .type {
          color: #7a3398;
        }
      }
      .main {
        .topItems {
          margin-top: 10px;
          text-align: center;
          li {
            width: 138px;
            height: 200px;
            margin: 0 20px;
            display: inline-block;
            position: relative;
            .hoverBox {
              width: 122px;
              height: 184px;
              z-index: 100;
              position: absolute;
              top: 8px;
              left: 8px;
              background: rgba(122, 51, 152, 0.2);
              border: 2px dashed #7a3398;
              border-radius: 14px;
            }
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .bottom {
          width: 675px;
          height: 200px;
          text-align: center;
          margin: 150px auto 0 auto;
          position: relative;
          .dropBox {
            width: 138px;
            height: 200px;
            position: absolute;
            top: 0;
            right: 0;
            display: inline-block;
            border-radius: 14px;
            img {
              width: 138px;
              height: 200px;
              border-radius: 14px;
            }
          }
          .currentBox {
            img {
              cursor: url('http://upload.jahr.janjk.com/hand.ico'), pointer;
            }
          }
          .dropBox:nth-child(1) {
            background: transparent;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1900px) {
  .card {
    display: table-cell;
    vertical-align: middle;
    .container {
      .tip {
        color: #5f9160;
        text-align: center;
        font-size: 78px;
        line-height: 819px;
        font-family: 'SYHeiBold';
        .type {
          color: #7a3398;
        }
      }
      .main {
        .topItems {
          margin-top: 13px;
          text-align: center;
          li {
            width: 179px;
            height: 260px;
            margin: 0 26px;
            display: inline-block;
            position: relative;
            .hoverBox {
              width: 159px;
              height: 239px;
              z-index: 100;
              position: absolute;
              top: 11px;
              left: 11px;
              background: rgba(122, 51, 152, 0.2);
              border: 1px dashed #7a3398;
              border-radius: 17px;
            }
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .bottom {
          width: 878px;
          height: 260px;
          text-align: center;
          margin: 195px auto 0 auto;
          position: relative;
          .dropBox {
            width: 179px;
            height: 260px;
            position: absolute;
            top: 0;
            right: 0;
            display: inline-block;
            border-radius: 18px;
            img {
              width: 179px;
              height: 260px;
              border-radius: 18px;
            }
          }
          .currentBox {
            img {
              cursor: url('http://upload.jahr.janjk.com/hand.ico'), pointer;
            }
          }
          .dropBox:nth-child(1) {
            background: transparent;
          }
        }
      }
    }
  }
}
</style>
