var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"container"},[(_vm.isPractice && !_vm.showTip && !_vm.showAns)?_c('Practice'):_vm._e(),(_vm.isPractice && _vm.showAns)?_c('isRight',{attrs:{"answer":_vm.correct_type}}):_vm._e(),(_vm.showTip && !_vm.showAns && _vm.isPractice)?_c('p',{staticClass:"tip"},[_vm._v(" 请按 "),(_vm.type == 1)?_c('span',{staticClass:"type"},[_vm._v("颜色")]):_vm._e(),(_vm.type == 2)?_c('span',{staticClass:"type"},[_vm._v("形状")]):_vm._e(),(_vm.type == 3)?_c('span',{staticClass:"type"},[_vm._v("数量")]):_vm._e(),_vm._v(" 归类 ")]):_vm._e(),_c('div',{staticClass:"main"},[_c('ul',{staticClass:"topItems"},_vm._l((_vm.dropDataList),function(item,index){return _c('li',{key:index,attrs:{"draggable":"false"},on:{"drop":function($event){return _vm.drop($event, index)},"dragenter":function($event){return _vm.dragenter($event, index)},"dragleave":_vm.dragleave,"dragover":function($event){$event.preventDefault();},"mousedown":function($event){$event.preventDefault();}}},[_c('img',{attrs:{"draggable":"false","src":item.src,"alt":""},on:{"mousedown":function($event){$event.preventDefault();}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hoverIndex == index),expression:"hoverIndex == index"}],staticClass:"hoverBox"})])}),0),(!_vm.showTip && !_vm.showAns)?_c('ul',{staticClass:"bottom"},_vm._l((_vm.cardList),function(item,index){return _c('li',{key:index},[(_vm.current == index)?_c('div',{staticClass:"dropBox currentBox",style:({
              right: (_vm.cardList.length - index) * 20 + 'px',
              zIndex: _vm.cardList.length - index,
              cursor: 'pointer',
            })},[_c('img',{attrs:{"draggable":"true","src":item.src,"alt":""},on:{"dragstart":function($event){return _vm.dragstart($event, _vm.currentCard)},"dragend":_vm.dragend}})]):_vm._e(),(_vm.current < index)?_c('div',{staticClass:"dropBox",style:({
              right: (_vm.cardList.length - index) * 20 + 'px',
              zIndex: _vm.cardList.length - index,
            }),attrs:{"draggable":"false"},on:{"dragstart":function($event){$event.preventDefault();}}},[_c('img',{attrs:{"draggable":"false","src":item.src,"alt":""},on:{"dragstart":function($event){$event.preventDefault();}}})]):_vm._e()])}),0):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }